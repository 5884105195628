<template>
    <main id="liste">
        <HeaderTab 
            :title="$t('gocardless.gocardless')"
            :swiper_tabs="swiper_tabs"
        />
        <div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<LoadingSpinner class="col-12" v-if="loading" />
                        <div v-else>
							<router-view />
						</div>
					</div>
				</div>
			</div>
		</div>
    </main>
</template>

<script type="text/javascript">
    import Navigation from '@/mixins/Navigation.js'
    import Gocardless from '@/mixins/Gocardless.js'

    export default {
        name: "Gocardless",
        mixins:[Navigation, Gocardless],
        data () {
            return {
                loading: false,
				swiper_tabs: [
                    // {
                    //     id: 'bankAccount',
                    //     label: 'gocardless.bank_accounts',
                    //     active: true,
                    //     href: 'gocardlessBankAccount'
                    // },
                    // {
                    //     id: 'mandate',
                    //     label: 'gocardless.mandates',
                    //     active: false,
                    //     href: 'gocardlessMandate'
                    // },
                    {
                        id: 'payment',
                        label: 'gocardless.payments',
                        active: false,
                        href: 'gocardlessPayment'
                    }
                ]
            }
        },

        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loading = true
                const params = new URLSearchParams(window.location.search);
                if(params.has('redirect_flow_id')) {
                    await this.addMandateComplete(params.get('redirect_flow_id'))
                    this.$router.push({ name: 'addressedInvoices' })
                    return true
                }

                this.loading = false
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
        }
    }
</script>
